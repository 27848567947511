import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HallService } from '@services/hall.service';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { Router } from '@angular/router';

export interface Halls {
    name: string;
    _id: string;
    role: string;
}

@Component({
    selector: 'app-search-list',
    templateUrl: './search-list.component.html',
    styleUrls: ['./search-list.component.scss']
})
export class SearchListComponent implements OnInit {
    query: String;
    private routeSub: any;
    halls: any;
    id: any;
    // mentoren: Mentoren[] = [];
    private hallSub: Subscription;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private route: ActivatedRoute,
        private hallService: HallService,
        private router: Router,
    ) {}

    ngOnInit() {
        // get routing parameter
        this.routeSub = this.route.queryParams.subscribe(params => {
            this.query = params['q'];
            console.log("query: " + this.query);
            this.getHallsList();
        });
    };

    getHallsList(){
        this.hallService.getSearchHall(this.query).subscribe(halls => {
            this.halls = halls;
            console.log(halls)
            this.id = halls["_id"];
        });
    }

    navigateToDetails(){
        this.router.navigate(['suche/' + this.halls._id]);
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log(changes)
        // if (changes) {
            this.getHallsList();
        // }
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    };
};
