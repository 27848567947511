<!-- <mat-toolbar>
  <a [routerLink]="['/']">{{appTitle}}</a>
  <a mat-button [routerLink]="['/item/create']" routerLinkActive="mat-accent" *ngIf="userIsAuthenticated">
    <mat-icon>add</mat-icon>
  </a>
  <a [routerLink]="['/myprofile']" routerLinkActive="mat-accent" *ngIf="userIsAuthenticated">
    <mat-icon>account_circle</mat-icon>
  </a>
  <button (click)="onLogout()" mat-raised-button color="secondary" *ngIf="userIsAuthenticated">Logout</button>
  <a mat-button [routerLink]="['/login']" routerLinkActive="mat-accent" *ngIf="!userIsAuthenticated">Login</a>
</mat-toolbar> -->
<div class="header_container">
  <div class="header_wrap">
    <!-- <button mat-button (click)="onLogout()" class="login-button" *ngIf="userIsAuthenticated">
      <mat-icon  class="logout" >logout</mat-icon>
      LogOut
    </button> -->
    <!-- <button mat-button class="login-button" (click)="onLogin()" *ngIf="!userIsAuthenticated">
      <mat-icon  class="logout" >login</mat-icon>
      Login
    </button> -->
    <div class="row">
      <div clasS="col-md-2"></div>
      <div clasS="col-md-8">
        <div class="header_center_part">
          <div *ngIf="isBackButton" class="back_button_wrap">
            <mat-icon class="back_button" (click)="navigateBack()">arrow_back</mat-icon>
          </div>
          <span class="logo_wrap"><a [href]="['/']"><img src="/assets/_logo.png" /></a></span>
          <div *ngIf="isSearchButton" class="search_button">
            <!-- <mat-icon class="back_button" (click)="navigateBack()">search</mat-icon> -->
            <mat-icon  class="back_button" (click)="toggleClass()">search</mat-icon>
            <div [class.hidden]="isHidden">
              <div class="search_nav_container">
                <div class="header_search">
                  <p>Finde Spielhallen in deiner Nähe</p>
                  <form (submit)="submitSearch(searchForm)" #searchForm="ngForm">
                    <mat-form-field>
                      <input matInput matNativeControl name="searchterm" ngModel type="searchterm" placeholder="Suchwort"
                        #searchtermInput="ngModel" required>
                    </mat-form-field>
                    <!-- <div class="button_wrapper">
                      <div class="search_button">
                        <button mat-raised-button color="accent" type="submit">Suchen abschicken</button>
                      </div>
                    </div> -->
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>  
      <div clasS="col-md-2"></div> 
    </div>


  </div>
</div>
