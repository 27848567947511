import { Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-abg',
    templateUrl: './agb.component.html',
    styleUrls: ['./agb.component.scss']
})

export class AGBComponent implements OnInit {

    constructor(
    ) { }

    ngOnInit() { }
}
