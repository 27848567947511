<div class="home_container">
    <div class="home_wrapper">
        <div class="row">
            <div class="col-md-2">
                <img class="hidden_mobile" src="../../../assets/banner/banner_desktop_160x600.png" alt="">
            </div>
            <div class="col-md-8 col-sm-12 home_middle_wrap">
                <div>
                    <h1>Finde Spielhallen in deiner Nähe</h1>
                    <p>Spielhallen-Name, Stadt oder PLZ in das Suchfeld eingeben.</p>
                    <form (submit)="submitSearch(searchForm)" #searchForm="ngForm">
                        <mat-form-field>
                            <input matInput matNativeControl name="searchterm" ngModel type="searchterm" placeholder="Name, Stadt oder PLZ" #searchtermInput="ngModel" required>
                        </mat-form-field>
                        <!-- <div class="button_wrapper">
                            <div class="search_button">
                                <button mat-raised-button color="accent" type="submit">Suchen</button> 
                            </div>
                        </div> -->
                    </form>
                </div>
            </div>
            <div class="col-md-2">
                <a href="https://casino-green.de">
                    <img class="hidden_mobile" src="../../../assets/banner/banner_desktop_160x600.png" alt="">
                </a>
            </div>
            <div class="col-md-12">
                <a href="https://casino-green.de">
                    <img class="hidden_desktop" src="../../../assets/banner/banner_mobile_338x120.png" alt="">
                </a>
            </div>
        </div>
    </div>    
</div>