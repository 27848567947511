import { Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-nutzungsbedingungen',
    templateUrl: './nutzungsbedingungen.component.html',
    styleUrls: ['./nutzungsbedingungen.component.scss']
})

export class NutzungsbedingungenComponent implements OnInit {

    constructor(
    ) { }

    ngOnInit() { }
}
