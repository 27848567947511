
<div class="home_container">
    <div class="home_wrapper">
        <div class="row">
            <div class="col-md-12">
                <h1>Kontakt</h1>
            </div>
        </div>
        <div class="row grey-bg">
            <div class="col-md-6">
                <p>FÜR BESUCHER</p>
                <p>Wir möchten darauf hinweisen, dass spieloguide.de selbst KEINE Spielhallen betreibt. Die Website dient ausschliesslich zur Information vom Standorten und deren Öffnungszeiten! (siehe Was ist Spieloguide?)</p>
                <p>Solltest du einen Fehler auf der Website gefunden haben oder eine Frage zu der Website spieloguide.de haben, kannst du gerne mit uns Kontakt aufnehmen.</p>
                <p>E-Mail: kontakt@spieloguide.de</p>
                <p>Facebook: facebook.com/spieloguide</p>
            </div>
            <div class="col-md-6">
                <p>FÜR BETREIBER</p>
                <p>Mit spieloguide.de haben wir ein Marketing-Werkzeug für Spielhallen-Betreiber erschaffen, welches Spielgäste direkt erreicht. Wir bieten die Lösungen für die zwei wichtigsten Fragen von Spielgästen:</p>
                <ul class="contact_li">
                    <li>Wo ist eine Spielhalle in der Umgebung?</li>
                    <li>Hat die Spielhalle geöffnet?</li>
                </ul>
                <p>Weitere Informationen unter: Was ist Spieloguide?</p>
                <p>Wir arbeiten bereits mit Betreiber zusammen, wenn du Informationen über unsere Konditionen oder anderweitige Fragen hast, gerne melden unter:</p>
                <p>E-Mail: betreiber@spieloguide.de</p>
                <p>Facebook: facebook.com/spieloguide</p>
            </div>
        </div>
    </div>    
</div>