import { Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-was-ist-spieloguide',
    templateUrl: './was-ist-spieloguide.component.html',
    styleUrls: ['./was-ist-spieloguide.component.scss']
})

export class WasIstSpieloguideComponent implements OnInit {

    constructor(
    ) { }

    ngOnInit() { }
}
