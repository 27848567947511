<div class="home_container">
    <div class="home_wrapper">
      <div class="row">
        <div class="col-md-12">
          <h1 class="pt-3">Sind Sie Betreiber dieses Standortes?</h1>
          <p>{{hallName}}</p>
          <div class="hall-create-auth" *ngIf="!userIsAuthenticated">
            <h3>Um eine Spielhalle einzutragen müssen sie ein Spieloguide-Benutzerkonto anlegen. Das Benutzerkonto ist
              komplett kostenlos.</h3>
            <div class="auth-data">
              <a [routerLink]="['/login']">Login</a>
              <p>oder</p>
              <a [routerLink]="['/registrierung']">Benutzerkonto anlegen</a>
            </div>
          </div>
          <div class="register_wrap" *ngIf="userIsAuthenticated">
            <form [formGroup]="rForm" #formDirective="ngForm">
  
                <mat-form-field>
                  <input matInput formControlName="firstname" type="text" placeholder="Vorname Ansprechpartner" >
                </mat-form-field>
  
                <mat-form-field>
                  <input matInput formControlName="lastname" type="text" placeholder="Nachname Ansprechpartner" >
                </mat-form-field>

                <mat-form-field>
                  <input matInput formControlName="companyname" type="text" placeholder="Firmenname " >
                </mat-form-field>
  
                <!-- <mat-form-field>
                  <input matInput formControlName="email" type="text" placeholder="E-Mail" >
                </mat-form-field> -->
  
              <mat-form-field>
                <input matInput formControlName="telefon" type="text" placeholder="Telefon" required>
              </mat-form-field>

              <div class="row">
                <div class="col-md-12">
                  <mat-slide-toggle class="operator-toggle" [checked]="moreThanOneHall" formControlName="moreThanOneHall">
                    <p>Ich betreibe mehrere Standorte (Spielhallen, Wettbüros, Automaten-Stellplätze)</p>
                  </mat-slide-toggle>
                </div>

              </div>

              <div>
                <mat-slide-toggle class="operator-toggle" [checked]="contactRequested" formControlName="contactRequested">
                  <p>Ich wünsche eine Kontaktaufnahme bzgl. weiteren Listungen  meiner Standorte in Spieloguide</p>
                </mat-slide-toggle>
              </div>
  
              <div class="submit-button-wrap operator-submit">
                <button mat-raised-button color="accent" class="profil_button center_button" type="button" (click)="submitForm(formDirective)" aria-label="Speichern">
                  Erstellen
                </button>
              </div>
  
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  