
<div class="footer">
  <div class="footer_container">
    <div class="row">
      <div class="col-md-12">
        <div class="footer_hall_create">
          <p>Spielhalle nicht gelistet? <img class="footer_arrow" src="../../../assets/arrow.svg" alt=""><a [routerLink]="['/halle/eintragen']">Hier Spielhalle eintragen</a></p>
        </div>
        <div class="footer_nav">
          <ul>
            <li><a  [routerLink]="['/was-ist-spieloguide']">Was ist Spieloguide?</a></li>
            <li><a  [routerLink]="['/nutzungsbedingungen']">Nutzungsbedinungen</a></li>
            <li><a  [routerLink]="['/impressum']">Impressum</a></li>
            <li><a  [routerLink]="['/agb']">AGB</a></li>
            <li><a  [routerLink]="['/kontakt']">Kontakt</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>