import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule  } from "@angular/forms";
import { AGBComponent  } from "./agb/agb.component";
import { ImpressumComponent  } from "./impressum/impressum.component";
import { KontaktComponent  } from "./kontakt/kontakt.component";
import { NutzungsbedingungenComponent  } from "./nutzungsbedingungen/nutzungsbedingungen.component";
import { WasIstSpieloguideComponent  } from "./was-ist-spieloguide/was-ist-spieloguide.component";
import { FAQComponent  } from "./faq/faq.component";
import { MaterialModule } from '../../material.module';
@NgModule({
    declarations: [
        AGBComponent,
        ImpressumComponent,
        KontaktComponent,
        NutzungsbedingungenComponent,
        WasIstSpieloguideComponent,
        FAQComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule
    ],
    bootstrap: [],
    exports: [
        AGBComponent,
        ImpressumComponent,
        KontaktComponent,
        NutzungsbedingungenComponent,
        WasIstSpieloguideComponent,
        FAQComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class StaticPagesModule {}
