import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartpageComponent } from '@components/startpage/startpage.component';
import { HallDetailComponent } from '@components/hall-detail/hall-detail.component';
import { SearchListComponent } from '@components/search-list/search-list.component';
import { HallListComponent } from '@components/hall-list/hall-list.component';
import { RegisterComponent } from '@components/register/register.component';
import { LoginComponent } from '@components/login/login.component';
import { HallCreateComponent } from '@components/hall-create/hall-create.component';
import { OperatorFormComponent } from '@components/operator-form/operator-form.component';
import { SuccessfulMessage } from '@components/successful-message/successful-message.component';
import { AGBComponent } from '@components/static-pages/agb/agb.component';
import { ImpressumComponent } from '@components/static-pages/impressum/impressum.component';
import { KontaktComponent } from '@components/static-pages/kontakt/kontakt.component';
import { NutzungsbedingungenComponent } from '@components/static-pages/nutzungsbedingungen/nutzungsbedingungen.component';
import { WasIstSpieloguideComponent } from '@components/static-pages/was-ist-spieloguide/was-ist-spieloguide.component';
import { FAQComponent } from '@components/static-pages/faq/faq.component';

const routes: Routes = [
    { path: '', redirectTo: '/', pathMatch: 'full' },
    { path: '', component: StartpageComponent },
    // { path: 'home', component: StartpageComponent },
    { path: 'spielhalle/:slug', component: HallDetailComponent },
    { path: 'wettbuero/:slug', component: HallDetailComponent },
    { path: 'automaten/:slug', component: HallDetailComponent },
    { path: 'suche', component: SearchListComponent },
    { path: 'spielhallen/stadt/:cityslug', component: HallListComponent },
    { path: 'wettbueros/stadt/:cityslug', component: HallListComponent },
    { path: 'automaten/stadt/:cityslug', component: HallListComponent },
    { path: 'registrierung', component: RegisterComponent },
    { path: 'login', component: LoginComponent },
    { path: 'halle/eintragen', component: HallCreateComponent },
    { path: 'operator/:slug', component: OperatorFormComponent },   // Sind sie Betreiber Formular
    { path: 'successful-message', component: SuccessfulMessage },
    { path: 'agb', component: AGBComponent },
    { path: 'impressum', component: ImpressumComponent },
    { path: 'kontakt', component: KontaktComponent },
    { path: 'nutzungsbedingungen', component: NutzungsbedingungenComponent },
    { path: 'was-ist-spieloguide', component: WasIstSpieloguideComponent },
    { path: 'faq', component: FAQComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
