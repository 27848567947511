import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, NavigationStart, Event  } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@services/auth.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  isHidden = true;
  private authListenerSubs: Subscription;
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  userIsAuthenticated: boolean = false;
  isBackButton: boolean = false;
  isSearchButton: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private location: Location
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          //back button logic
          if (event.url.includes('/automaten') || event.url.includes('/wettbuero')|| event.url.includes('/spielhalle')) {
            this.isBackButton = true;
          } else {
            this.isBackButton = false;
          }
          //search button logic
          if (event.url.includes('/automaten') || event.url.includes('/wettbuero')|| event.url.includes('/spielhalle') || event.url.includes('/suche')) {
            this.isSearchButton = true;
          } else {
            this.isSearchButton = false;
          }
      }
     });
   }

  ngOnInit() {
    this.authListenerSubs = this.authService.getIsAuth().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(isAuthenticated => {
      this.userIsAuthenticated = isAuthenticated
    });
  }

  onLogout() {
    this.authService.logout();
  }

  onLogin(){
    this.router.navigate(['/login'])
  }
  
  toggleClass() {
    this.isHidden = !this.isHidden;
  }

  navigateBack() {
    this.location.back()
  }

    // Redirect to search?q=SEARCHTERM
    submitSearch(form: NgForm) {
        if (form.invalid) {
            return;
          }
        this.router.navigate(['suche'] , { queryParams: { q: form.value.searchterm}});  
        this.isHidden = true;
    };
}
