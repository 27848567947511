import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
// import { User } from '../../user/user.model'
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
    private authStatusSubs: Subscription;
    isLoading: boolean;
    rForm: UntypedFormGroup;
    passwordMinLength = 3;

    constructor(
      private _formBuilder: UntypedFormBuilder,
      private authService: AuthService,
      private router: Router,
      ) {}

    ngOnInit() {
        this.authStatusSubs = this.authService.getAuthStatusListener()
            .subscribe(authStatus => {
                this.isLoading = false;
            });
        this.rForm = this._formBuilder.group({
          email: ['', [Validators.required, Validators.email]],
          username: ['', [Validators.required]],
          password: ['', [Validators.required, Validators.minLength(this.passwordMinLength)]]
        });        
  }

  onRegister(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
      return
    }

    this.isLoading = true;
    // this.user = {
    //   username: form.value.username,
    //   email: form.value.email,
    //   password: form.value.password
    // }
    this.authService.register(
      this.rForm.value.email,
      this.rForm.value.username,
      this.rForm.value.password,
      );
    // this.authService.registerUser(this.user)
  }

  ngOnDestroy() {
    this.authStatusSubs.unsubscribe();
  }
}
