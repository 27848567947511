import { Component, OnInit } from '@angular/core';
import { HallService } from '@services/hall.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-hall-list',
  templateUrl: './hall-list.component.html',
  styleUrls: ['./hall-list.component.scss']
})
export class HallListComponent implements OnInit {
	private sub: any;
	halls: any = [];
    kindOfHalls: string;
    citySlug: string;
    headerOne: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private hallService: HallService,
    ) { }

    ngOnInit(): void {
        switch (this.activatedRoute.snapshot.url[0].path) {
            case 'wettbueros':
                this.kindOfHalls = 'wettbuero';
                this.headerOne = 'Wettbüros in ';
                break;
            case 'automaten':
                this.kindOfHalls = 'automaten';
                this.headerOne = 'Automaten in ';
                break;
            default:
                this.kindOfHalls = 'spielhalle';
                this.headerOne = 'Spielhallen in ';
                break;
        };

        this.sub = this.activatedRoute.params.subscribe(params => {
            this.citySlug = params['cityslug'];
        });
        
        this.hallService.getHalls({ kind: this.kindOfHalls, cityslug: this.citySlug})
            .subscribe(data => {
                console.log(data)
                this.halls = data;
                this.headerOne = this.headerOne + this.halls[0].cityname;
            });
    };

}
