
<div class="home_container">
    <div class="home_wrapper">
        <div class="row">
            <div class="col-md-12">
                <h1>Impressum</h1>
                <div class="impressum_wrap">
                    <p>Spieloguide ist ein Projekt der Rechnerhaus GmbH</p>
                    <h4>ADRESSE</h4>
                    <p>Rechnerhaus GmbH <br>
                        vertreten durch die Gesellschafter und Geschäftsführer: <br>
                        Clemens Weidenauer und Danny Schmitt <br>
                        Ernst-Ludwig-Straße 32 <br>
                        68623 Lampertheim
                    </p>
                    <h4>HINWEIS</h4>
                    <p>Bevor Sie mit uns in Kontakt treten: Rechnerhaus GmbH betreibt KEINE Spielhallen!
                        Die Website spieloguide.de ist lediglich eine Liste von Spielhallen und deren Adressen in Deutschland.
                    </p>
                    <h4>TELEFON / FAX</h4>
                    <p>
                        Tel: 06206-9462690 <br> Fax: 06206-9462691
                    </p>
                    <h4>E-MAIL</h4>
                    <p>kontakt@spieloguide.de</p>
                    <h4>INTERNET</h4>
                    <p>WordPress Agentur Rechnerhaus</p>
                    <h4>VERTRETUNGSBERECHTIGTE GESELLSCHAFTER</h4>
                    <p>Clemens Weidenauer und Danny Schmitt</p>
                    <h4>UMSATZSTEUER-IDENTIFIKATIONSNUMMER GEMÄSS § 27 A UMSATZSTEUERGESET</h4>
                    <p>DE276973880</p>
                    <h4>REGISTERGERICHT</h4>
                    <p>
                        Amtsgericht Darmstadt, HRB 89837 <br>
                        Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV <br>
                        Clemens Weidenauer <br>
                        Ernst-Ludwig-Straße 32 <br>
                        68623 Lampertheim <br>
                    </p>
                    <h4>HAFTUNGSHINWEIS</h4>
                     <p>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>
                     <h4>NUTZUNG VON GOOGLE ANALYTICS</h4>
                     <p>Unsere Homepage benutzt Google Analytics, einen Webanalysedienst von Google. Google Analytics verwendet so genannte Cookies (kleine Textdateien), die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch die Cookie erzeugten Informationen über Ihre Benutzung dieser Homepage (einschließlich Ihrer IP-Adresse) werden an einen Server von Google in den USA übertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Homepage-Betreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dieses gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google in Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung in Ihrer Browser-Software verhindern, wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website in vollem Umfang nutzen können. Durch die Nutzung unserer Homepage erklären Sie sich mit der Bearbeitung, der über Sie erhobenen Daten durch Google, in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.</p>
                     <!-- <h4>PARTNER</h4>
                     <a href="">Hausfinanzierung mit Baufinanzierung plus</a> -->
                </div>
            </div>
        </div>
    </div>    
</div>