import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http' ;
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { Subject,BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


export interface Halls {
    _id?: string;
    firstname?: string;
    lastname?: string;
    companyname?: string;
    email?: string;
    telefon?: string;
    name?: string;
    kind?: string;
    bookmaker? : string;
    streetNr?: string;
    plz?: string;
    cityname?: string;
    state?: string;
    moreThanOneHall?: boolean;
    contactRequested?: boolean;
    hallId?: string
  }
@Injectable({
  providedIn: 'root'
})

export class HallService {
    private res: any;
    halls: any
	private hallsListener = new Subject<any>();
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    constructor(
        private http: HttpClient
    ) {}

	// Getter-function for halls-Listener
	getHallsListener() {
		return this.hallsListener.asObservable();
    }
    
    // create hall
    createHall( firstname: string, lastname: string, email: string, name: string, kind: string, bookmaker: string, streetNr: string, plz: string, cityname: string, state: string) {
        const hallData: Halls = { 
            firstname: firstname,
            lastname: lastname,
            email: email,
            name: name,
            kind: kind,
            bookmaker: bookmaker ,
            streetNr: streetNr,
            plz: plz,
            cityname: cityname,
            state: state
        };
        return this.http
            .post<any>(
                environment.apiUrl + "/h/create",
                hallData
            );
      }

      operatorInformation( hallId: string, firstname: string, lastname: string, companyname: string, telefon: string, moreThanOneHall: boolean, contactRequested: boolean) {
        const hallData: Halls = { 
            hallId: hallId,
            firstname: firstname,
            lastname: lastname,
            telefon : telefon,
            companyname: companyname,
            moreThanOneHall: moreThanOneHall,
            contactRequested: contactRequested
        };
        return this.http
            .post<any>(
                environment.apiUrl + "/h/operator",
                hallData
            );
    }

    // get halls with index search in database
    getSearchHall(searchterm) {
        const postBody = { searchterm };
        return this.http.post<{searchterm: string}>(environment.apiUrl + 'search', postBody);

    };

    // get one hall
    getHall(hallId) {
        return this.http.get(environment.apiUrl + 'h/' + hallId).pipe(map(data => data));;
    };


//     getHallList() {
// 		this.http.get<Halls[]>(environment.apiUrl + 'h/list')
//           .pipe(map(data => data), takeUntil(this._unsubscribeAll))
// 			.subscribe(data => {
//                 this.halls = data;
//                 this.hallsListener.next([...this.halls]);
// 			});
//   }

    // get one hall
    getHalls(postData) {
        return this.http.post(environment.apiUrl + 'h/halls', postData);
    };
    
    // get one hall
    getHallBySlug(hallSlug) {
        return this.http.get(environment.apiUrl + 'hs/' + hallSlug);
    };
}
