import { Component, OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'app-startpage',
    templateUrl: './startpage.component.html',
    styleUrls: ['./startpage.component.scss']
})

export class StartpageComponent implements OnInit {

    constructor(
        private router: Router,
    ) { }

    ngOnInit() { }

    // Redirect to search?q=SEARCHTERM
    submitSearch(form: NgForm) {
        if (form.invalid) {
            return;
          }
        this.router.navigate(['suche'] , { queryParams: { q: form.value.searchterm}});  
    };
}
