
<div class="home_container">
    <div class="home_wrapper">
        <div class="row">
            <div class="col-md-12">
                <h1>Was ist Spieloguide</h1>
            </div>
        </div>

            <div class= row>
                <div class="col-md-12 grey-bg">
                    <h1>Spielhalle in der Nähe finden</h1>
                    <p>Spieloguide beantwortet dir folgende Fragen:</p>
                    <ul>
                        <li>Wo befindet sich eine Spielhalle in der Umgebung?</li>
                        <li>Hat die Spielhalle geöffnet?</li>
                    </ul>
                </div>
            </div>

            <div class="row grey-bg">
                <div class="col-md-6 ">
                    <p>WIE FINDE ICH SPIELHALLEN IN MEINER NÄHE?</p>
                    <p>Wir glauben, dass viele Fragen im Alltag einfach in eine Suchmaschine eingegeben werden. 
                        Dazu gehört auch, was sich in meiner direkten Umgebung befindet. 
                        Mit spieloguide.de möchten wir den ortsunkundigen Spielgast zu einem qualitativ 
                        hochwertigen Standort führen.
                    </p>
                </div>
            </div>

            <div class="row grey-bg text_left">
                <div class="col-md-6">
                    <p>IST DIE SPIELHALLE NICHT BEI SPIELOGUIDE GELISTET?</p>
                    <p>Wir sind auf eure Hilfe angewiesen</p>
                    <a href="">Hier könnt ihr eine Spielhalle eintragen</a>
                    <p>Oder ihr schreibt uns über <a href=""> facebook.com/spieloguide</a> oder <a href="">twitter.com/spieloguide</a></p>
                </div>
                <div class="col-md-6">
                  
                </div>
            </div>
            <div class="row grey-bg">
                <div class="col-md-6">
                  
                </div>
                <div class="col-md-6 ">
                    <p>HAT DIE SPIELHALLE GEÖFFNET?</p>
                    <p>Mit wachsender Anzahl von Standorten bei spieloguide.de, können wir dem Besucher immer mehr
                        Öffnungszeiten präsentieren. Die Öffnungszeiten 
                        sind für den Spielgast die Kerninformation vor dem Besuch einer Spielhalle.
                    </p>
                    <p>Der klassische Ablauf ist einfach:</p>
                    <ul>
                        <li>Spielhalle über Suchmaschine finden</li>
                        <li>Öffnunszeiten der Spielhalle erfahren</li>
                        <li>Spielhalle besuchen</li>
                    </ul>
                </div>
            </div>

            <div class="row grey-bg">
                <div class="col-md-6 ">
                    <p>STANDORTE BEI SPIELOGUIDE</p>
                    <p>Unsere Standort-Einträge stammen aus Branchenverzeichnissen und/oder 
                        wurden von Spielhallen-Betreiber selbst eingetragen. 
                        Selbstverständlich kann unsere Datenqualität durch Besucher erhöht werden.
                    </p>
                    <p>Hier könnt ihr Kontakt aufnehmen.</p>
                </div>
            </div>
    </div>    
</div>