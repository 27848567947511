<div>
    <mat-form-field>
        <input matInput [(ngModel)]="formdataBookmaker.bezeichnung" placeholder="Bezeichnung">
    </mat-form-field>

    <div mat-dialog-actions>
        <button mat-raised-button color="accent" [mat-dialog-close]="sendData()" cdkFocusInitial>Hinzufügen</button>
    </div>
    
</div>
