<div class="home_container">
    <div class="home_wrapper">
        <div class="row">
            <div class="col-md-12">
                <div class="faq_wrap">
                    <mat-accordion>
                        <!-- Frage 1 -->
                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="accordion_header">
                                    <h1>Frage 1</h1>
                                </div>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                            <div class="accordion_content">
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor 
                                    invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
                                     justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                                </p>
                            </div>
                        </mat-expansion-panel>
                        <!-- Frage 2 -->
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                  <div class="accordion_header">
                                      <h1>Frage 2</h1>
                                  </div>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                              <div class="accordion_content">
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor 
                                    invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
                                     justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                                </p>
                              </div>
                        </mat-expansion-panel>
                        <!-- Frage 3 -->
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                  <div class="accordion_header">
                                      <h1>Frage 3</h1>
                                  </div>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                              <div class="accordion_content">
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor 
                                    invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
                                     justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                                </p>
                              </div>
                        </mat-expansion-panel>
                        <!-- Frage 4 -->
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                  <div class="accordion_header">
                                      <h1>Frage 4</h1>
                                  </div>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                              <div class="accordion_content">
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor 
                                    invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
                                     justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                                </p>
                              </div>
                        </mat-expansion-panel>                       
                    </mat-accordion>
                </div>
            </div>
        </div>
    </div>    
</div>