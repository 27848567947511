import { Component, OnInit, Inject } from '@angular/core';
import { HallService } from "../../services/hall.service";
import { AuthService } from "../../services/auth.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators, NgForm, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http' ;
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-hall',
  templateUrl: './hall-create.component.html',
  styleUrls: ['./hall-create.component.scss']
})
export class HallCreateComponent implements OnInit {
    rForm: UntypedFormGroup;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    hallOperator = false;
    userIsAuthenticated: boolean = false;
    private authListenerSubs: Subscription;
    private _jsonURL = '../../../assets/vorlage/wettanbieterVorlage.json';
    isBookmaker: boolean = false;
    maker;

    constructor(
        public hallService: HallService,
        private router: Router,
        private _formBuilder: UntypedFormBuilder,
        public snackBar: MatSnackBar,
        private authService: AuthService,
        private http: HttpClient,
        private dialog: MatDialog,
    ) {
 
     }

     public getJSON(): Observable<any> {
        return this.http.get(this._jsonURL);
      }

    ngOnInit() {
        this.authListenerSubs = this.authService.getIsAuth().pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(isAuthenticated => {
            this.userIsAuthenticated = isAuthenticated
        });
        this.getJSON().subscribe(data => {
            this.maker = data.wettanbieter;
            console.log(data.wettanbieter);
           });

        this.rForm = this._formBuilder.group({
        hallOperator: [false],
        firstname  : ['', Validators.required],
        lastname     : ['',Validators.required],
        email     : ['',Validators.required],
        name     : ['',Validators.required],
        kind  : ['', Validators.required],
        bookmaker: [""],
        streetNr  : ['',Validators.required],
        plz  : ['', Validators.required],
        cityname  : ['', Validators.required],
        state  : ['', Validators.required],
        });
        this.setValidators();

        this.rForm.get('kind').valueChanges.pipe(
            takeUntil(this._unsubscribeAll)
        )
        .subscribe(kind => {
            if(kind === "wettbuero") {
                this.isBookmaker = true;
            } else{
                this.isBookmaker = false;
            }
        });
    }

    openBookmakerDialog(heading, type): void {
        const dialogRef = this.dialog.open(BookmakerDialog, {
            width: '500px',
        //   position: {
        //     top: '0px',
        //     right: '0px'
        //   },
          data: {
            heading: heading,
            type: type
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            if (result.type == 'bookmaker') {
              this.maker.push({
                bezeichnung: result.formdata.bezeichnung
              });
              // autofill select
              if (this.rForm.value.bookmaker == null) {
                this.rForm.get('bookmaker').setValue([]);
              }
             this.rForm.value.bookmaker.push(result.formdata.bezeichnung);
            }
          };
        });
      };

  setValidators() {
    const firstname = this.rForm.get('firstname');
    const lastname = this.rForm.get('lastname');
    const email = this.rForm.get('email');

    if (this.rForm.value.hallOperator === false) {
        firstname.setValidators(null);
        lastname.setValidators(null);
        email.setValidators(null);
    }

    if (this.rForm.value.hallOperator === true) {
        firstname.setValidators([Validators.required]);
        lastname.setValidators([Validators.required]);
        email.setValidators([Validators.required]);
    }

    this.rForm.get('hallOperator').valueChanges.pipe(
            takeUntil(this._unsubscribeAll)
        )
        .subscribe(hallOperator => {
            if (hallOperator === false) {
                firstname.setValidators(null);
                lastname.setValidators(null);
                email.setValidators(null);
            }

            if (hallOperator === true) {
                firstname.setValidators([Validators.required]);
                lastname.setValidators([Validators.required]);
                email.setValidators([Validators.required]);
            }
            firstname.updateValueAndValidity();
            lastname.updateValueAndValidity();
            email.updateValueAndValidity();
        });
    firstname.updateValueAndValidity();
    lastname.updateValueAndValidity();
    email.updateValueAndValidity();
  }



  /**
   * submit data
   */
  submitForm(formDirective: FormGroupDirective) {
    console.log("hallOperatore. " +this.rForm.value.hallOperator)
    
    console.log(this.rForm);
    if (this.rForm.invalid) {
        this.rForm.get("firstname").markAsTouched();
        this.rForm.get("lastname").markAsTouched();
        this.rForm.get("email").markAsTouched();
        this.rForm.get("name").markAsTouched();
        this.rForm.get("streetNr").markAsTouched();
        this.rForm.get("plz").markAsTouched();
        this.rForm.get("cityname").markAsTouched();
        this.rForm.get("state").markAsTouched();
        this.rForm.get("kind").markAsTouched();
        return;
    }
    console.log("hier : " + this.rForm.value)
    this.hallService.createHall(
        this.rForm.value.firstname,
        this.rForm.value.lastname,
        this.rForm.value.email,
        this.rForm.value.name,
        this.rForm.value.kind,
        this.rForm.value.bookmaker,
        this.rForm.value.streetNr,
        this.rForm.value.plz,
        this.rForm.value.cityname,
        this.rForm.value.state,
    ).pipe(
        takeUntil(this._unsubscribeAll)
    ).subscribe((res:any) => {
        console.log(res)
        this.authService.showSuccessSnackBar("Spielhalle" + " " + this.rForm.value.name + " " + "wurde " + "angelegt")
        formDirective.resetForm();
        this.rForm.reset();
        //   this.router.navigate(["/hall-list"]);
    });
  }
}


// Dialog
@Component({
    selector: 'bookmaker-dialog',
    templateUrl: 'bookmaker.html',
  })
  export class BookmakerDialog {
    formdataBookmaker = {
      bezeichnung: ''
    };
  
  
    constructor(
      public dialogRef: MatDialogRef<BookmakerDialog>,
      @Inject(MAT_DIALOG_DATA) public data: {
        heading: string;
        type: string;
      }) {}
  
      sendData() {
          return {
            type: this.data.type,
            formdata: this.formdataBookmaker
          };
  }; 
}