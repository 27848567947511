<div class="home_container">
  <div class="home_wrapper">
    <div class="row">
      <div class="col-md-12">
        <h1 class="pt-3">Spielhalle eintragen</h1>
        <div class="hall-create-auth" *ngIf="!userIsAuthenticated">
          <h3>Um eine Spielhalle einzutragen müssen sie ein Spieloguide-Benutzerkonto anlegen. Das Benutzerkonto ist
            komplett kostenlos.</h3>
          <div class="auth-data">
            <a [routerLink]="['/login']">Login</a>
            <p>oder</p>
            <a [routerLink]="['/registrierung']">Benutzerkonto anlegen</a>
          </div>
        </div>
        <div class="register_wrap" *ngIf="userIsAuthenticated">
          <form [formGroup]="rForm" #formDirective="ngForm">
            <div>
              <mat-slide-toggle class="example-margin" [checked]="hallOperator" formControlName="hallOperator">
                Sind sie Betreiber der Spielhalle?
              </mat-slide-toggle>
            </div>

            <div [hidden]="!rForm.get('hallOperator').value">
              <mat-form-field>
                <input matInput formControlName="firstname" type="text" placeholder="Vorname" >
              </mat-form-field>

              <mat-form-field>
                <input matInput formControlName="lastname" type="text" placeholder="Nachname" >
              </mat-form-field>

              <mat-form-field>
                <input matInput formControlName="email" type="text" placeholder="E-Mail" >
              </mat-form-field>
            </div>

            <mat-form-field>
              <input matInput formControlName="name" type="text" placeholder="Name der Spielhalle" required>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Typ</mat-label>
              <mat-select formControlName="kind" type="role" required>
                <mat-option value="spielhalle">Spielhalle</mat-option>
                <mat-option value="wettbuero">Wettbüro</mat-option>
                <mat-option value="automaten">Automaten-Stellplatz</mat-option>
              </mat-select>
            </mat-form-field>

            <div [hidden]="!isBookmaker"> 
              <!-- <mat-form-field>
                <input matInput formControlName="bookmaker" type="text" placeholder="Wettanbieter">
              </mat-form-field> -->

              <mat-form-field>
                <mat-select #bookmaker formControlName="bookmaker" matNativeControl panelClass="add_element"
                    placeholder="Wettanbieter" name="bookmaker" multiple>
                    <div class="select_box">
                        <div class="options_wrap">
                            <mat-option *ngFor="let bookmaker of maker " [value]="bookmaker.bezeichnung">
                                {{bookmaker.bezeichnung}}
                            </mat-option>
                        </div>
                        <div class="select-instant-create">
                            <button mat-mini-fab (click)="bookmaker.close()" type="button">
                                <mat-icon class="bookmaker_icon" aria-label="Schließen">done</mat-icon>
                            </button>
                            <button mat-mini-fab class="align_right"
                                (click)="openBookmakerDialog('Wettanbieter', 'bookmaker')" type="button">
                                <mat-icon class="bookmaker_icon" aria-label="Hinzufügen">add</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-select>
            </mat-form-field>
            </div>
     

            <mat-form-field>
              <input matInput formControlName="streetNr" type="text" placeholder="Straße & Hausnummer" required>
            </mat-form-field>

            <mat-form-field>
              <input matInput formControlName="plz" type="text" placeholder="PLZ" required>
            </mat-form-field>

            <mat-form-field>
              <input matInput formControlName="cityname" type="text" placeholder="Stadtname" required>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Bundesland</mat-label>
              <mat-select formControlName="state" type="role" required>
                <mat-option value="baden-wuerttemberg">Baden-Württemberg</mat-option>
                <mat-option value="bayern">Bayern</mat-option>
                <mat-option value="berlin">Berlin</mat-option>
                <mat-option value="brandenburg">Brandenburg</mat-option>
                <mat-option value="bremen">Bremen</mat-option>
                <mat-option value="hamburg">Hamburg</mat-option>
                <mat-option value="hessen">Hessen</mat-option>
                <mat-option value="mecklenburg-vorpommern">Mecklenburg-Vorpommern</mat-option>
                <mat-option value="niedersachsen">Niedersachsen</mat-option>
                <mat-option value="nordrhein-westfalen">Nordrhein-Westfalen</mat-option>
                <mat-option value="rheinland-pfalz">Rheinland-Pfalz</mat-option>
                <mat-option value="saarland">Saarland</mat-option>
                <mat-option value="sachsen">Sachsen</mat-option>
                <mat-option value="sachsen-anhalt">Sachsen-Anhalt</mat-option>
                <mat-option value="schleswig-holstein">Schleswig-Holstein</mat-option>
                <mat-option value="thueringen">Thüringen</mat-option>
              </mat-select>
            </mat-form-field>

            <div class="submit-button-wrap">
              <button mat-raised-button color="accent" class="profil_button center_button" type="button" (click)="submitForm(formDirective)" aria-label="Speichern">
                Erstellen
              </button>
            </div>


          </form>
        </div>
      </div>
    </div>
  </div>
</div>
