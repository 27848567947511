import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  	private authStatusSubs: Subscription;
  	isLoading: boolean;
  	loginData: object;

	constructor(private authService: AuthService) {}

	ngOnInit() {
		this.authStatusSubs = this.authService.getAuthStatusListener()
			.subscribe(authStatus => {
				this.isLoading = false;
	  		});
  	}

  	onLogin(form: NgForm) {
		if (form.invalid) { return };
		this.isLoading = true;
		this.loginData = {
	  		username: form.value.username,
	  		password: form.value.password
		};
		this.authService.login(this.loginData);
  	}

  	ngOnDestroy() {
		this.authStatusSubs.unsubscribe();
  	}
}
