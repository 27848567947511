
<div class="home_container">
  <div class="home_wrapper">
      <div class="row">
          <div class="col-md-12">
            <div class="register_container">
                <form (submit)="onLogin(loginForm)" #loginForm="ngForm">
                  <mat-form-field>
                    <input matNativeControl name="username" ngModel type="username" placeholder="Username" #usernameInput="ngModel" required>
                    <mat-error *ngIf="usernameInput.invalid">Please enter a Username.</mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <input matNativeControl name="password" ngModel type="password" placeholder="Password" #passwordInput="ngModel" required>
                    <mat-error *ngIf="passwordInput.invalid">Please enter a Password.</mat-error>
                  </mat-form-field>
                  <button mat-raised-button color="accent" type="submit" class="profil_button center_button">Login</button>
                </form>
            </div>
          </div>
      </div>
</div>