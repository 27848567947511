import { Component, OnInit } from '@angular/core';
import { HallService } from "../../services/hall.service";
import { AuthService } from "../../services/auth.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators, NgForm, FormGroupDirective } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common'

@Component({
  selector: 'app-operator-form',
  templateUrl: './operator-form.component.html',
  styleUrls: ['./operator-form.component.scss']
})
export class OperatorFormComponent implements OnInit {
  rForm: UntypedFormGroup;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  moreThanOneHall = false;
  contactRequested = false;
  userIsAuthenticated: boolean = false;
  private authListenerSubs: Subscription;
  hallId;
  hallName;
  id;
  constructor(
    public hallService: HallService,
    private router: Router,
	private route: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    private authService: AuthService,
    private location: Location
  ) { }

  ngOnInit() {
    this.hallId = this.route.snapshot.params['slug'];
    this.hallService.getHall(this.hallId).subscribe(res => {
        console.log(res)
        this.hallName = res["name"]
        this.id = res["_id"]
      });

    this.authListenerSubs = this.authService.getIsAuth().pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated
      });

    this.rForm = this._formBuilder.group({
      firstname  : ['', Validators.required],
      lastname     : ['',Validators.required],
      companyname     : [''],
      telefon     : ['',Validators.required],
      moreThanOneHall: [false],
      contactRequested: [false],
    });
  }

  /**
   * submit data
   */
  submitForm(formDirective: FormGroupDirective) {
    if (this.rForm.invalid) {
        this.rForm.get("firstname").markAsTouched();
        this.rForm.get("lastname").markAsTouched();
        this.rForm.get("telefon").markAsTouched();
        return;
    };
    
  // console.log("hier : " + this.rForm.value)
  // console.log(  this.id)
  this.hallService.operatorInformation(
      this.id, 
      this.rForm.value.firstname,
      this.rForm.value.lastname,
      this.rForm.value.companyname,
      this.rForm.value.telefon,
      this.rForm.value.moreThanOneHall,
      this.rForm.value.contactRequested,
  ).pipe(
      takeUntil(this._unsubscribeAll)
  ).subscribe((res:any) => {
      console.log(res)
      // this.authService.showSuccessSnackBar("Spielhalle" + " " + this.rForm.value.name + " " + "wurde " + "angelegt")
      formDirective.resetForm();
      this.rForm.reset();
      // this.location.back()
        this.router.navigate(["/successful-message"]);
  });
  }
}
