import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NavigationComponent } from './components/navigation/navigation.component';
import { FooterComponent } from './components/footer/footer.component';
import { HallDetailComponent } from '@components/hall-detail/hall-detail.component';
import { HallListComponent } from '@components/hall-list/hall-list.component';
import { StartpageComponent } from '@components/startpage/startpage.component';
import { SearchListComponent } from '@components/search-list/search-list.component';
import { AngularMaterialModule } from './angular-material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AuthInterceptor } from './auth/auth.interceptor';
import { LoginComponent } from '@components/login/login.component';
import { RegisterComponent } from '@components/register/register.component';
import { MaterialModule } from './material.module';
import { AgmCoreModule } from '@agm/core'; // Angular2 Google Maps
import { NgxJsonLdModule } from '@ngx-lite/json-ld'; // Angular SEO with schema and JSON-LD
import { HallCreateComponent } from '@components/hall-create/hall-create.component';
import { OperatorFormComponent } from '@components/operator-form/operator-form.component';
import { SuccessfulMessage } from '@components/successful-message/successful-message.component';
import { infoBoxComponent } from '@components/info-box/info-box.component';
import {StaticPagesModule} from '@components/static-pages/static-pages.module'
import { BookmakerDialog } from '@components/hall-create/hall-create.component';
const googleMapsParams = {
    apiKey: 'AIzaSyDk_bTnChd8Oa9-XmjdCwT6-ERAc_4rtD4', // environment.GOOGLE_MAPS_API_KEY,
    libraries: ['places']
};

@NgModule({
    declarations: [
        AppComponent,
        HallDetailComponent,
        HallListComponent,
        LoginComponent,
        NavigationComponent,
        SearchListComponent,
        StartpageComponent,
        FooterComponent,
        RegisterComponent,
        HallCreateComponent,
        OperatorFormComponent,
        SuccessfulMessage,
        infoBoxComponent,
        BookmakerDialog
    ],
    imports: [
        AngularMaterialModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        MaterialModule,
        AgmCoreModule.forRoot(googleMapsParams),
        NgxJsonLdModule,
        StaticPagesModule,
        NgxGoogleAnalyticsModule.forRoot('G-EPDT0GLYW4'),
        NgxGoogleAnalyticsRouterModule
    ],
    providers: [{
        provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor,
        multi: true
    }],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    bootstrap: [AppComponent]
})
export class AppModule { }
