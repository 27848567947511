
<div class="home_container">
    <div class="home_wrapper">
        <div class="row">
            <div class="col-md-12">
                <div class="successful-message_wrap">
                    <h1>Vielen Dank für Ihre Nachricht, ein Mitarbeiter von Spieloguide, wird sich schnellstmöglich mit Ihnen in Verbidung setzen</h1>
                </div>
                <div class="home_link">
                    <a [routerLink]="['/']">Startseite</a>
                </div>

            </div>
        </div>
    </div>    
</div>