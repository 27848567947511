 <div class="detail_container">
    <div class="row">
        <div class="col-md-12">
			<ngx-json-ld [json]="schema"></ngx-json-ld>
            <div class="hall_detail">
                <div class="row">
					<div class="col-md-2">
						<a href="https://casino-green.de">
							<img  class="hidden_mobile" src="../../../assets/banner/banner_desktop_160x600.png" alt="">
						</a>
					</div>
                    <div class="col-md-8 col-sm-12"> 
						<h1>{{ hall?.name }}</h1>
						<div class="detail_info">
							<div *ngIf="hall?.kind == 'automaten'">
								<p class="automaten_stellplatz">Automaten</p>
							</div>
							<div *ngIf="hall?.kind == 'wettbuero'">
								<p class="wettbuero">Wettbüro</p>
							</div>
							<div *ngIf="hall?.kind == 'spielhalle'">
								<p class="spielhalle">Spielhalle</p>
							</div>
							<p>{{ hall?.streetNr }},</p>
							<p> {{ hall?.plz }} {{ hall?.cityname }}</p>
						</div>

						<div class="col-md-12"> 
							<agm-map
							[latitude]="latitude"
							[longitude]="longitude"
							[maxZoom]="map.options.maxZoom"
							[minZoom]="map.options.minZoom"
							[zoom]="map.options.zoom"
							[zoomControl]="map.options.zoomControl">
								<agm-marker
								[latitude]="latitude"
								[longitude]="longitude"
								[iconUrl]="icon">
								</agm-marker>
							</agm-map>
							<!-- <google-map></google-map> -->
							<!-- <button mat-raised-button color="accent" (click)="startNavigation(hall?.location.coordinates)">Route planen</button>  -->
						</div>

						<div>
							<p><a [routerLink]="['/operator/' + hall?.slug]">Sind Sie Betreiber dieses Standortes?</a></p>
						</div>
                    </div>
                    <!-- <div class="col-md-4 col-sm-4" > 
						<div class="icon_wrap" *ngIf="hall?.certified">
							<mat-icon matTooltip="{{hall?.slug}}" >verified</mat-icon>
						</div>
						<div class="operator_link">
							<a [routerLink]="['/operator', hallId]">Sind Sie Betreiber dieses Standortes?</a>
							<mat-icon class="infobox-icon" (click)="openInfoBox('operator')">help</mat-icon>
					
						</div>
					</div> -->
					<!--  -->
					<!-- <div class="col-md-12"> 
						<mat-accordion>
							<mat-expansion-panel>
							  <mat-expansion-panel-header>
								<mat-panel-title>
								 Öffnungszeiten
								</mat-panel-title>
								<mat-panel-description>
								  {{hall?.openinghours.normaldays[0]}}
								</mat-panel-description>
							  </mat-expansion-panel-header>
							  <p></p>
							</mat-expansion-panel>
						  </mat-accordion>
					</div> -->
					<div class="col-md-2">
						<a href="https://casino-green.de">
							<img  class="hidden_mobile" src="../../../assets/banner/banner_desktop_160x600.png" alt="">
						</a>
					</div>
                </div>

            </div>
		</div>
		<div class="col-md-12">
			<a href="https://casino-green.de">
				<img class="hidden_desktop" src="../../../assets/banner/banner_mobile_338x120.png" alt="">
			</a>
		</div>
    </div>
</div>