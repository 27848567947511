import { Component, Inject, OnInit} from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface infoBoxData {
    headline: string;
    text: string;
    position: string;
  }
  
@Component({
    selector: 'info-box',
    templateUrl: 'info-box.component.html',
  })
  export class infoBoxComponent implements OnInit{
    InfoboxData;
    isOperator: boolean = false;
    
    constructor(
      public dialogRef: MatDialogRef<infoBoxComponent>,
      public snackBar: MatSnackBar,
      @Inject(MAT_DIALOG_DATA) public data: infoBoxData
    ) {}
  
    CloseQuestion(): void {
      this.dialogRef.close();
    }
  
    ngOnInit() {
        this.InfoboxData = this.data.position;
        console.log(this.InfoboxData )

        if(this.InfoboxData == "operator") {
            this.isOperator = true;
        }
    }
  }