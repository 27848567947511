import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject, BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';
import { takeUntil } from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

export interface AuthData {
  email?: string;
  username: string;
  password: string;
}

@Injectable({
    providedIn: 'root'
})


export class AuthService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private token: string;
  private tokenTimer: any;
  private userId: string;
  private authStatusListener = new Subject<boolean>();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    private http: HttpClient,
    private router: Router,
    private location: Location,
    private _snackBar: MatSnackBar
  ) {
  }

  // Getter-function for token
  getToken() {
    return this.token;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getUserId() {
    return this.userId;
  }

  // Getter-function for authStatusListener
  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  // register User with redirect to login
  // registerUser(user) {
  //   this.http.post<{token: string, userId: string}>(environment.apiUrl + 'u/register', user).subscribe(response => {
  //     this.router.navigate(['/login'])
  //   }, error => {
  //     this.authStatusListener.next(false)
  //   })
  // }

  // User login
  login(loginData) {
    return this.http
      .post<{token: string, exp: number, user: any}>(environment.apiUrl + 'u/login', loginData)
      .subscribe(response => {
        const token = response.token;
        this.token = token;
        if (token)  {
          const expirationDateUnix = response.exp;                     // expiration Date as Unix timestamp
          // console.log("login expirationDateUnix " + expirationDateUnix)
          this.isAuthenticated.next(true);                                // set Authentication Status
          this.userId = response.user._id;
          this.authStatusListener.next(true);
          const expirationDate = new Date(expirationDateUnix * 1000);  // JavaScript-Time is in Milliseconds
          this.saveAuthData(token, expirationDate, this.userId);       // Save expiration Date as Date-Object (in localStorage as String)
          const now = new Date();
          const expiresIn = expirationDate.getTime() - now.getTime();  // Duration to expiration
          // console.log("expiresIn: " + expiresIn + " Milliseconds")
          this.setAuthTimer(expiresIn);     
          this.location.back()                           // Set timer in seconds
          // this.router.navigate(['/']);
        }
      }, error => {
        this.showErrorSnackBar(error.error.message);
        this.authStatusListener.next(false);
      });
  }

  register(
    email: string,
		username: string,
		password: string,
	) {
		const authData: AuthData = {
      email: email,
			username: username.toLowerCase(),
			password: password,
		};
		this.http
			.post<{ token: string; exp: number, user: any }>(
        environment.apiUrl + "u/register",
				authData
			).pipe(
				takeUntil(this._unsubscribeAll)
			)
			.subscribe((response) => {
        console.log(response)
        this.showSuccessSnackBar(response["msg"])
        this.isAuthenticated.next(true);       
        this.authStatusListener.next(true);
        this.location.back()   
        // this.router.navigate(["/halle/eintragen"]);
        // this.login({ username: username, password: password }).subscribe(
        //   async (res) => {     
        //     this.router.navigateByUrl('/app', { replaceUrl: true });
        //   }
        // );
			}, error => {
        this.showErrorSnackBar(error.error.message);
        this.authStatusListener.next(false);
			});
  }

  // User logout
  logout() {
    console.log('logged out');
    this.token = null;
    this.isAuthenticated.next(false);
    this.authStatusListener.next(false);
    this.userId = null;
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.location.back();
  }

  // store token information to localStorage
  private saveAuthData(token: string, expirationDate: Date, userId: string) {
    localStorage.setItem('token', token);
    // console.log("saveAuthData expirationDate.toISOString()" + expirationDate.toISOString())
    localStorage.setItem('expiration', expirationDate.toISOString());  // date of expiration as String
    localStorage.setItem('userId',  userId);
  }

  // clear localStorage
  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('expiration');
    localStorage.removeItem('userId');
  }

  // get data von local storage
  private getAuthData() {
    const token = localStorage.getItem('token');
    const expiration = localStorage.getItem('expiration');
    const userId = localStorage.getItem('userId');
    if (!token || !expiration || !userId) {
      return; // do nothing if one is missing
    }
    return {
      token,
      expirationDate: new Date(expiration),
      userId
    };
  }

  // set auth Timer in Lilliseconds
  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {      // logout after expiration
      this.logout();
    }, duration);
  }

  // authenticate automaticly with the data from localStorage
  autoAuthUser() {
    const authInfo = this.getAuthData();
    if (!authInfo) {
      return;
    }
    const now = new Date();
    const expiresIn = authInfo.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInfo.token;
      this.isAuthenticated.next(true);
      this.userId = authInfo.userId;
      console.log('expiresIn: ' + expiresIn + ' Milliseconds');
      this.setAuthTimer(expiresIn);
      this.authStatusListener.next(true);
    } else {
      this.logout();
    }
  }

  showSuccessSnackBar(message) {
    this._snackBar.open(message , '', {
      duration: 5000,
      panelClass: 'successSnackBar'
    });
  }

  showErrorSnackBar(message) {
    this._snackBar.open(message , '', {
      duration: 5000,
      panelClass: 'errorSnackBar'
    });
  }
}
