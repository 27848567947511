<div class="home_container">
  <div class="home_wrapper">
      <div class="row">
          <div class="col-md-12">
            <div class="register_container">
                <mat-spinner *ngIf="isLoading"></mat-spinner>
                <form [formGroup]="rForm" #formDirective="ngForm">
                  <mat-form-field>
                    <input matInput formControlName="email"  type="email" placeholder="E-Mail" required email> 
                     <!-- <mat-error *ngIf="emailInput.invalid">Please enter a valid E-Mail.</mat-error> -->
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput formControlName="username"  type="text" placeholder="Username" required>
                    <!-- <mat-error *ngIf="usernameInput.invalid">Please enter a Username.</mat-error> -->
                  </mat-form-field>
                  <mat-form-field>
                    <input  type="password" formControlName="password"  matInput placeholder="Password"  required>
                    <!-- <mat-error *ngIf="passwordInput.invalid">Please enter a Password.</mat-error> -->
                  </mat-form-field>
                  <button mat-raised-button color="accent" class="profil_button center_button" type="button" (click)="onRegister(formDirective)"  [disabled]="!rForm.valid">Registrieren</button>
                </form>
            </div>
          </div>
      </div>
  </div>
</div>

  