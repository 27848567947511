import { Component, OnInit } from '@angular/core';
import { HallService } from '@services/hall.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { infoBoxComponent } from '@components/info-box/info-box.component';
import { Title, Meta } from '@angular/platform-browser';

@Component({
	selector: 'app-hall-detail',
	templateUrl: './hall-detail.component.html',
	styleUrls: ['./hall-detail.component.scss']
})
export class HallDetailComponent implements OnInit {
	private sub: any;
	hall: any;
	hallId: string;
	hallSlug: string;
	id;
	map = {
		options: {
		  streetViewControl: false,
		  panControl: false,
		  maxZoom: 20,
		  minZoom: 2,
		  zoom: 18,
		  scrollwheel: false,
		  zoomControl: true,
		  styles: [{"stylers":[{"saturation":-100},{"gamma":1}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi.place_of_worship","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"poi.place_of_worship","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"geometry","stylers":[{"visibility":"simplified"}]},{"featureType":"water","stylers":[{"visibility":"on"},{"saturation":50},{"gamma":0},{"hue":"#50a5d1"}]},{"featureType":"administrative.neighborhood","elementType":"labels.text.fill","stylers":[{"color":"#333333"}]},{"featureType":"road.local","elementType":"labels.text","stylers":[{"weight":0.5},{"color":"#333333"}]},{"featureType":"transit.station","elementType":"labels.icon","stylers":[{"gamma":1},{"saturation":50}]}]
		}
	  };
	latitude: number;
	longitude: number;
	icon = {
		url: "../../../assets/Spielo-Mapmarker.png",
	}
	googleMapsUrl: string = 'https://www.google.com/maps/search/?api=1&query=';
	schema;
	kind;
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private hallService: HallService,
		private dialog: MatDialog,
		private titleService: Title, private metaService: Meta
	) { }

    ngOnInit() {
		this.hallSlug = this.route.snapshot.params['slug'];
		console.log(this.hallSlug)
		this.hallService.getHallBySlug(this.hallSlug).subscribe(res => {
			console.log(res)
			this.hall = res;
			this.hallId = res["_id"];
			console.log(this.hall)
			 this.latitude = res["location"].coordinates[1]
			 this.longitude = res["location"].coordinates[0]

			 this.schema = {
                "@context": "https://schema.org",
                "@type": "Spielhalle",
                "address": {
                  "@type": "PostalAddress",
                  "addressLocality": this.hall.cityname,
				  "addressRegion": this.hall.state,
				  "postalCode": this.hall.plz,
                  "streetAddress":  this.hall.streetNr
                },
				"name": this.hall.name,
				"openingHours": [
					"Mo-Sa ",
					"Mo-Th ",
					"Fr-Sa "
				  ]
			  };

			  // Meta Title kind
			  if(this.hall.kind == "spielhalle") {
				this.kind = "Spielhalle";
			  } else if (this.hall.kind == "wettbuero") {
				this.kind = "Wettbüro";
			  } else if (this.hall.kind == "automaten") {
				this.kind = "Automaten";
			  }

			  // set Meta title
			  const title = this.kind + " - " + this.hall.name + " - " + this.hall.streetNr + " - " + this.hall.plz + " - " +  this.hall.cityname;
			  this.titleService.setTitle(title);

			  // description = title
			  this.metaService.addTags([
				{name: 'keywords', content: this.kind + " , " + this.hall.name},
				{name: 'description', content: this.kind + " - " + this.hall.name + " - " + this.hall.streetNr + " - " + this.hall.plz + " - " +  this.hall.cityname},
				{name: 'robots', content: 'index, follow'}
			  ]);
		  });

        // this.sub = this.route.params.subscribe(params => {
        //     this.hallSlug = params['slug'];
        // });

        // this.hallService.getHallBySlug(this.id).subscribe(data => {
		// 	this.hall = data;
		// 	console.log(this.hall)
		// });
	};

	openInfoBox(position): void {
        console.log("position");
        const dialogRef = this.dialog.open(infoBoxComponent, {
            width: '500px',
            data: {
                position: position,
            }
        });
    };
	
	startNavigation(coords) {
			// open url in new browser tab
			let url = this.googleMapsUrl + String(coords[1]) + ',' +  String(coords[0]);
			window.open(url, '_blank');
	}
}
