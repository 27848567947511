<!-- <p>Spielhallen</p>
<div *ngFor="let hall of halls">
    <p>{{ hall.kind }}</p>
    <h1>
        <a [routerLink]="['/spielhalle', hall.slug]">{{ hall.name }}</a>
    </h1>
    <p>{{ hall.streetNr }} {{ hall.plz }} 
        <a [routerLink]="['/spielhallen/stadt', hall.cityslug]">{{ hall.cityname }}</a></p>
</div> -->
<div class="search_container">
    <div class="row">
        <div class="col-md-2">
            <a href="https://casino-green.de">
                <img class="hidden_mobile" src="../../../assets/banner/banner_desktop_160x600.png" alt="">
            </a>
        </div>
        <div class="col-md-8">
            <div class="search_headline">
                <h1>{{headerOne}}</h1>
            </div>

            <div class="hall_list" *ngFor="let hall of halls">
                    <!-- Automaten -->
                    <div *ngIf="hall.kind == 'automaten'" class="row" [routerLink]="['/automaten/' + hall.slug]">
                        <div class="col-md-6"> 
                            <div class="certificate">
                                <mat-icon *ngIf="hall?.certified" matTooltip="{{hall?.slug}}" >verified</mat-icon>
                                <h1>{{ hall.name }}</h1>
                                <div *ngIf="hall.kind == 'automaten'">
                                    <p class="automaten_stellplatz tags">Automaten</p>
                                </div>
                                <div *ngIf="hall.kind == 'wettbuero'">
                                    <p class="wettbuero tags">Wettbüro</p>
                                </div>
                                <div *ngIf="hall.kind == 'spielhalle'">
                                    <p class="spielhalle tags">Spielhalle</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3"> 
                            <p>{{ hall.streetNr }}</p>
                            <p> {{ hall.plz }} {{ hall.cityname }}</p>
                        </div>
                        <div class="col-md-3">
                            <button  [routerLink]="['/automaten/' + hall.slug]" mat-raised-button color="accent" class="profil_button hidden_mobile">Profil ansehen</button> 
                        </div>
                    </div>
                    <!-- wettbuero -->
                    <div *ngIf="hall.kind == 'wettbuero'" class="row"  [routerLink]="['/wettbuero/' + hall.slug]">
                        <div class="col-md-6"> 
                            <div class="certificate">
                                <mat-icon *ngIf="hall?.certified" matTooltip="{{hall?.slug}}" >verified</mat-icon>
                                <h1>{{ hall.name }}</h1>
                                <div *ngIf="hall.kind == 'automaten'">
                                    <p class="automaten_stellplatz tags">Automaten</p>
                                </div>
                                <div *ngIf="hall.kind == 'wettbuero'">
                                    <p class="wettbuero tags">Wettbüro</p>
                                </div>
                                <div *ngIf="hall.kind == 'spielhalle'">
                                    <p class="spielhalle tags">Spielhalle</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3"> 
                            <p>{{ hall.streetNr }}</p>
                            <p> {{ hall.plz }} {{ hall.cityname }}</p>
                        </div>
                        <div class="col-md-3">
                            <button  [routerLink]="['/wettbuero/' + hall.slug]" mat-raised-button color="accent" class="profil_button hidden_mobile">Profil ansehen</button>
                        </div>
                    </div>
                    <!-- spielhalle -->
                    <div *ngIf="hall.kind == 'spielhalle'" class="row"  [routerLink]="['/spielhalle/' + hall.slug]">
                        <div class="col-md-6"> 
                            <div class="certificate">
                                <mat-icon *ngIf="hall?.certified" matTooltip="{{hall?.slug}}" >verified</mat-icon>
                                <h1>{{ hall.name }}</h1>
                                <div *ngIf="hall.kind == 'automaten'">
                                    <p class="automaten_stellplatz tags">Automaten</p>
                                </div>
                                <div *ngIf="hall.kind == 'wettbuero'">
                                    <p class="wettbuero tags">Wettbüro</p>
                                </div>
                                <div *ngIf="hall.kind == 'spielhalle'">
                                    <p class="spielhalle tags">Spielhalle</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3"> 
                            <p>{{ hall.streetNr }}</p>
                            <p> {{ hall.plz }} {{ hall.cityname }}</p>
                        </div>
                        <div class="col-md-3">
                            <button  [routerLink]="['/spielhalle/' + hall.slug]" mat-raised-button color="accent" class="profil_button hidden_mobile">Profil ansehen</button> 
                        </div>
                    </div>

            </div>
            <div class="col-md-12">
                <a href="https://casino-green.de">
                    <img class="hidden_desktop" src="../../../assets/banner/banner_mobile_338x120.png" alt="">
                </a>
            </div>
        </div>

        <div class="col-md-2">
            <a href="https://casino-green.de">
                <img class="hidden_mobile" src="../../../assets/banner/banner_desktop_160x600.png" alt="">
            </a>
        </div>
    </div>
</div>