import { Component } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Router, NavigationStart, Event } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isHidden = true;
  private authListenerSubs: Subscription;
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  userIsAuthenticated: boolean = false;
  backButton: boolean = false
  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location
  ) {
 
  }

  ngOnInit() {
    this.authService.autoAuthUser();     // check localStorage for token and expiration
    this.authListenerSubs = this.authService.getIsAuth().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(isAuthenticated => {
      this.userIsAuthenticated = isAuthenticated;
    });
 
  }

  toggleClass() {
    this.isHidden = !this.isHidden;
  }
}
