
<div class="home_container">
    <div class="home_wrapper">
        <div class="row">
            <div class="col-md-12">
                <h1>Nutzungsbedingungen</h1>
                <div class="nutzungsbedingungen_wrap">
                    <ul>
                        <li>
                            Im Rahmen der Teilnahme an den Diensten und Bewertungsmöglichkeiten von Spieloguide steht es den Kunden frei, Texte und Informationen im Rahmen der Beitragsmöglichkeiten einzustellen. Diese Informationen werden den anderen Nutzern und Besuchern von Spieloguide durch diese Einstellung zugänglich gemacht. Die Kunden erklären sich ausdrücklich mit dieser Veröffentlichung einverstanden.   
                        </li>
                        <li>
                            Die Kunden von Spieloguide erklären sich einverstanden und garantieren, dass die von ihnen eingestellten Beiträge nicht gegen gesetzliche Bestimmungen verstoßen. Im Besonderen erklären sie sich bereit keine pornographischen, obszönen, diffamierenden, verleumderischen, beleidigenden, bedrohenden, volksverhetzenden oder rassistischen Inhalte, Informationen oder anderes entsprechendes Material einzustellen.   
                        </li>
                        <li>
                            Urheberrechte und alle ähnlichen Schutzrechte Dritter sind bei der Einstellung von Inhalten, Informationen oder anderen entsprechenden Materialien bei Spieloguide zu beachten.
                        </li>
                        <li>
                            Das Spieloguide Team behält sich weiterhin vor, Informationen, Inhalte oder andere Materialien zu entfernen, die gegen die Verhaltensregeln verstoßen.
                        </li>
                    </ul>
                    <p>Spieloguide Team, 1. März 2014</p>
                </div>
            </div>
        </div>
    </div>    
</div>